<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  
<div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
  <header class="mb-auto">
    <div>
      <h3 class="float-md-start mb-0"><img class="logo" src="/img/LogoSolid.svg" alt=""></h3>
      <!-- <nav class="nav nav-masthead justify-content-center float-md-end">
        <a class="nav-link fw-bold py-1 px-0 active" aria-current="page" href="#">Home</a>
        <a class="nav-link fw-bold py-1 px-0" href="#">Features</a>
        <a class="nav-link fw-bold py-1 px-0" href="#">Contact</a>
      </nav> -->
    </div>
  </header>
  <main class="px-3">
    <div v-if="!answer">
      <h1>Обращение по вывозу ТКО</h1>
      <p class="lead">Если у вас есть замечания по вывозу твёрдых коммунальных отходов, напишите нам!</p>
      <hr>
      <form >
        <div class="mb-3 p-3">
          <label for="formFileLg" class="file-upload btn btn-success btn-block rounded-pill shadow"><i class="fa fa-photo" style="margin-right: 10px; font-size: 20px;"></i>Сфотографируйте контейнерную площадку
            <input style="display: none;" class="form-control form-control-lg" id="formFileLg" type="file" capture="camera" accept="image/*" @change="changeImage">
          </label>

          <div id="preview">
            <img class="p-3" v-if="item.imageUrl" :src="item.imageUrl" width="100%" />
            <h2 class="p-3" v-if="photo_error">{{ photo_error }}</h2>
          </div>
        </div>
        <div class="mb-3 p-3">
          <label for="exampleFormControlTextarea1" class="form-label">При необходимости, добавьте описание.</label>
          <textarea class="form-control" v-model='comment' id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <div class="mb-3 p-3 ">
          <input class="form-check-input p-3" type="checkbox"  v-model="phone_send_show" id="flexCheckDefault">
          <label class="form-check-label p-3" for="flexCheckDefault">
            Я хочу получить ответ на своё обращение.
          </label>
        </div>
        <div class="mb-3 p-3" v-if="phone_send_show">
          <label for="phone" class="form-label">Если хотите получить ответ лично, оставьте номер своего телефона.</label>
          <imask-input v-model="value" :mask="mask.mask" :lazy=false @accept:unmasked="onAcceptUnmasked"/>
          
        </div>
        <hr >
        <div class="mb-3">
          <button type="button" class="btn btn-success btn-lg p-3 rounded-pill" v-on:click="SendAnswer">Отправить обращение</button>
        </div>
      </form>
    </div>
    <div v-else>
      
      <h1 v-if="error">{{ error }}</h1>
      <h1 v-else-if="phone_send_show">Ваше обращение принято!<br>В ближайшее время Вам будет дан ответ.</h1>
      <h1 v-else >Ваше обращение принято!</h1>
    </div>
  </main>
  <footer class="mt-auto ">
    <div class="row">
      <p>Региональный оператор по обращению с ТКО на территории Нижнего Новгорода (без Сормовского района), а также в Дзержинске и Володарском районе 
        <a href="http://nizhecologia-nn.ru" target="_blank">ООО "Нижэкология-НН"</a></p>
    </div>
    <div class="row">
      <p>Разработано <a href="https://mag-rf.ru/" target="_blank" class="">ООО «Веолия МАГ»</a>, 2024г.</p>
    </div>
  </footer>

  </div>
</template>

<script>
import axios from 'axios'
import Compressor from 'compressorjs';
import { IMaskComponent } from 'vue-imask';

async function compress(file, quality, maxHeight, maxWidth) {
  return await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      maxHeight,
      maxWidth,
      success: (compressedFile) => {
        if (compressedFile instanceof File) return resolve(compressedFile);
        else {
          const compressedFileFromBlob = new File([compressedFile], file.name, {
            type: compressedFile.type,
          });
          return resolve(compressedFileFromBlob);
        }
      },
      error: reject,
    });
  });
}

  export default {
    name: 'HelloWorld',
    components: {'imask-input': IMaskComponent},
    data() {
      return {
        answer: false,
        value: '',
        phone_send_show: false,
        comment: '',
        kp_number: this.$route.query.cid,
        error: '',
        photo_error: '',
        phoneNumber: "",

        mask: {
          mask: '{+7} (000) 000-0000',
          lazy: false
        },

        item: {
            image : null,
            imageUrl: null
        }
      }
    },
    // directives: {
    //   imask: IMaskDirective
    // },
    methods: {
      onAcceptUnmasked (unmaskedValue) {
        this.phoneNumber = unmaskedValue;
      },
      changeImage(e) {
        const file = e.target.files[0]

        if (file.name.length > 100) {
          this.item.imageUrl = ''
          this.item.image = ''
          this.photo_error = 'Название фотографии не может содержать более 100 символов.'
        }

        if (file.size > 7000000) {
          this.item.imageUrl = ''
          this.item.image = ''
          this.photo_error = 'Фотография слишком большая'
        } 

        if ((file.size < 7000000) && (file.name.length < 100)) {
          this.photo_error = ''
          this.item.image = file
          this.item.imageUrl = URL.createObjectURL(file)
        }
      },

      SendAnswer() {
        this.answer = !this.answer

        if (this.kp_number) {

          let domain = this.$store.state.domain
          let kp_number = this.kp_number
          let comment = this.comment
          let phoneNumber = this.phoneNumber

          if (this.phone_send_show === false) {
            phoneNumber = ''
          }

          if (this.item.image) {
          const c = new Compressor(this.item.image, {
            quality: 0.7,
            maxHeight: 640,
            maxWidth: 480,
            convertTypes: ['image/png', 'image/webp'],
            convertSize: 1000000,
            success(result) {

              const compressedFileFromBlob = new File([result], result.name, {
                type: result.type,
              });

              console.log('compressedFileFromBlob: ', compressedFileFromBlob)
              axios.post(domain+'/api/appeals/', 
                {
                  kp_number: kp_number,
                  comment: comment,
                  phone: phoneNumber,
                  image: compressedFileFromBlob,
                },
                {
                  headers:{
                    'Content-Type': 'multipart/form-data'
                  }
                },
              )

            },
            error(err) {
              console.log(err.message);
            },
          });
        } else {
          axios.post(domain+'/api/appeals/', 
                {
                  kp_number: kp_number,
                  comment: comment,
                  phone: phoneNumber,
                  
                },
                {
                  headers:{
                    'Content-Type': 'multipart/form-data'
                  }
                },
              )
        }



      } else {
        this.error = 'Вы заполнили не все обязательные поля!'
      }

      },
    }
  }
</script>

<style>
/*
 * Globals
 */

.rounded-pill {
  border-radius: 50rem;
}

.rounded-pill::after {
  border-radius: 0 50rem 50rem 0;
}

/* Custom default button */
.btn-light,
.btn-light:hover,
.btn-light:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}


/*
 * Base structure
 */

body {
  /* text-shadow: 0 .05rem .1rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5rem rgba(44, 172, 5, 0.5); */
  height: 100vh;
}

.cover-container {
  max-width: 42em;
}


/*
 * Header
 */

.nav-masthead .nav-link {
  color: rgba(255, 255, 255, .5);
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}
</style>