import { createStore } from 'vuex'

export default createStore({
  state: {
    // domain: 'http://localhost:8081',
    domain: 'https://amlogger-back.mag-rf.ru'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
